import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es7.object.entries.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
import moment from "moment";
export var InputBuilder = function InputBuilder() {
  return {
    setPagination: function setPagination(page, pageSize) {
      var paginationPrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      var key = paginationPrefix != "" ? "".concat(paginationPrefix, "Pagination") : "pagination";
      this[key] = {
        page: page,
        pageSize: pageSize
      };
      return this;
    },
    setFilter: function setFilter(filter) {
      var filterPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      if (filter) {
        var key = filterPrefix != "" ? "".concat(filterPrefix, "Filters") : "filters";
        if (this[key] == null) {
          this[key] = {
            groups: []
          };
        }
        this[key].groups.push(filter);
      }
      return this;
    },
    setSort: function setSort(sortBy, sortDirection, sortRelation) {
      var sortPrefix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
      if (sortBy) {
        var key = sortPrefix != "" ? "".concat(sortPrefix, "Sort") : "sort";
        this[key] = {
          key: sortBy,
          direction: sortDirection,
          relation: sortRelation
        };
      }
      return this;
    },
    setId: function setId(id) {
      var idPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var key = idPrefix != "" ? "".concat(idPrefix, "id") : "id";
      this[key] = id;
      return this;
    },
    setInput: function setInput(name, input) {
      this[name] = input;
      return this;
    },
    build: function build() {
      var input = {};
      Object.entries(this).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        if (typeof value !== "function") {
          input[key] = value;
        }
      });
      return input;
    }
  };
};
export var GraphInputBuilder = function GraphInputBuilder() {
  return {
    setPropertyId: function setPropertyId(id) {
      this.propertyId = id;
      return this;
    },
    setTenancyId: function setTenancyId(id) {
      this.tenancyId = id;
      return this;
    },
    setPortfolioId: function setPortfolioId(id) {
      this.portfolioId = id;
      return this;
    },
    setDateFrom: function setDateFrom(date) {
      this.from = date;
      return this;
    },
    setDateTo: function setDateTo(date) {
      this.to = date;
      return this;
    },
    setPeriodType: function setPeriodType(periodType) {
      this.periodType = periodType;
      return this;
    },
    setCategory: function setCategory(category) {
      this.category = category;
      return this;
    },
    setProjectAnnualRent: function setProjectAnnualRent(projectAnnualRent) {
      this.projectAnnualRent = projectAnnualRent;
      return this;
    },
    build: function build() {
      return {
        input: this.buildInput()
      };
    },
    buildInput: function buildInput() {
      return _objectSpread({
        propertyId: this.propertyId,
        tenancyId: this.tenancyId,
        portfolioId: this.portfolioId,
        from: moment(this.from),
        to: moment(this.to),
        periodType: this.periodType,
        rentCategory: this.category
      }, this.projectAnnualRent && {
        projectAnnualRent: true
      });
    }
  };
};